import React from "react"
import Layout from "../components/global/layout"
import SEO from "../components/global/seo"
import SingleProduct from "../components/single-product-page/singleProduct"

import reliefCreamProduct from "../images/product-and-package/relief-cream-2.jpg"
import reliefCreamImage from "../images/products-without-packaging/relief-cream.png"

// related product images
import asdaLogo from "../images/stockists/asda.png"
import BandMLogo from "../images/stockists/bandm.png"
import bootsLogo from "../images/stockists/boots.png"
import homebargainsLogo from "../images/stockists/homebargains.png"
import bodycareLogo from "../images/stockists/bodycare.png"
import saversLogo from "../images/stockists/savers.png"
import amazonLogo from "../images/stockists/amazon.png"
import superdrugLogo from "../images/stockists/superdrug.png"
import sainsburysLogo from "../images/stockists/sainsburys.png"
import coopLogo from "../images/stockists/coop.png"
import morrisonsLogo from "../images/stockists/morrisons.png"

import tescoLogo from "../images/stockists/tesco.png"
import waitroseLogo from "../images/stockists/waitrose.png"
//import wilkoLogo from "../images/stockists/wilko.png"

import coldIcon from "../images/icons/product-icon/winter.svg"
import outdoorIcon from "../images/icons/product-icon/outdoors.svg"
import overnightIcon from "../images/icons/product-icon/overnight.svg"

const product = {
  image: reliefCreamProduct,
  productImage: reliefCreamImage,
  title: <h1 className="product-overview__heading">Blistex Relief Cream.</h1>,
  alt: "Blistex Relief Cream.",
  icons: [
    { title: "outdoors", icon: outdoorIcon },
    { title: "cold weather", icon: coldIcon },
    { title: "at night", icon: overnightIcon },
  ],
  subTitle: (
    <h2 className="product-overview__sub-heading">
      <br />
      Blistex Relief Cream has a soothing and protective formula to relieve
      cracked, chapped and sore lips.
      <br />
    </h2>
  ),
  disclaimer: "",
  overviewCopy: (
    <div>
      <br />
      <p>
        it also helps relieve the discomfort of occasional cold sores on the
        lips.
      </p>
      <p>
        <strong>
          Blistex Relief Cream is a medicine for chapped lips. always read the
          label.
        </strong>
      </p>
    </div>
  ),
  listID: "996cd2ae80",
  stockists: [
    {
      stockistName: "Superdrug",
      stockistImage: superdrugLogo,
      stockistLink:
        "https://www.superdrug.com/Skin/Face-Skin-Care/Lip-Care/Lip-Balms/Blistex-Relief-Cream-5g/p/512958",
      classModifier: "",
      showLink: "true",
    },
    {
      stockistName: "Amazon",
      stockistLink: "https://www.amazon.co.uk/dp/B09MTXZY2Y",
      stockistImage: amazonLogo,
      classModifier: "",
      showLink: "true",
    },
    {
      stockistName: "B&M",
      stockistLink: "",
      stockistImage: BandMLogo,
      classModifier: "",
      showLink: "false",
    },
    {
      stockistName: "Home Bargains",
      stockistLink: "",
      stockistImage: homebargainsLogo,
      classModifier: "",
      showLink: "false",
    },
    {
      stockistName: "Bodycare",
      stockistLink:
        "https://www.bodycareonline.co.uk/product/blistex-relief-cream-5g/",
      stockistImage: bodycareLogo,
      classModifier: "",
      showLink: "",
    },
    {
      stockistName: "Morrisons",
      stockistLink:
        "https://groceries.morrisons.com/products/blistex-relief-cream-268600011",
      stockistImage: morrisonsLogo,
      classModifier: "",
      showLink: "true",
    },
    {
      stockistName: "Savers",
      stockistImage: saversLogo,
      stockistLink:
        "https://www.savers.co.uk/Skin-Care/Lip-Care/Dry-%26-Chapped-Lip-Treatments/Blistex-Relief-Cream-5g/p/512958",
      classModifier: "",
      showLink: "true",
    },
    {
      stockistName: "Waitrose",
      stockistImage: waitroseLogo,
      stockistLink:
        "https://www.waitrose.com/ecom/products/blistex-relief-cream/766593-777410-777411",
      classModifier: "",
      showLink: "true",
    },
  ],
  whenToUseCopy: (
    <div>
      <p>
        if your lips are cracked and chapped, apply every hour. your lips are
        more sensitive during extreme weather conditions, so you may need a
        little more care than normal.
      </p>
      <p>
        for cold sores - when first symptoms arise, gently apply every hour to
        rapidly relieve any discomfort.
      </p>
    </div>
  ),
  ingredients: (
    <p>
      <strong>
        do not use if you are sensitive to any of the ingredients listed below.
      </strong>
      <br />
      active ingredients w/w: aromatic ammonia solution 6.04%, strong ammonia
      solution 0.10%, liquefied phenol 0.494%. also contains: white soft
      paraffin, modified lanolin, sorbitan palmitate, polysorbate 40, peppermint
      oil, sodium saccharin, ethanol 96%, racemic camphor, cineole, purified
      water. this cream also contains lanolin which may cause local skin
      reactions. (e.g, contact dermatitis).
    </p>
  ),
  relatedProducts: [
    {
      productName: "",
      productLink: "",
      productImage: "",
    },
    {
      productName: "",
      productLink: "",
      productImage: "",
    },
  ],
}

const BlistexReliefCream = () => (
  <Layout noHeaderInLayout={false} makeFooterSticky={false}>
    <SEO title="Blistex Relief Cream." />
    <SingleProduct data={product} inStock={true} />
  </Layout>
)

export default BlistexReliefCream
